/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveBreaks, resolveMeta } from "@dataResolvers";
import { Project as PageTemplate } from "@pageTemplates";

export const query = graphql`
query($slug: [String]) {
  craft {
    entry(section: "project", slug: $slug) {
      ... on Craft_project_project_Entry {
        #Meta
        title
        slug
        url
        metaTitle
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #Hero
        heroHeading
        heroDescription
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        projectCategories {
          title
          slug
        }
        #Environment Stats
        descriptor0
        descriptor1
        projectList {
          ...on Craft_projectList_projectList_BlockType {
            listLabel
            listContent
            color
          }
        }
        projectStats {
          ...on Craft_projectStats_projectStat_BlockType {
            statLabel
            statContent
            color
          }
        }
        #Benefits
        benefits {
          ...on Craft_benefits_benefit_BlockType {
            benefitName
            benefitDetails
            benefitImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            iconLocation
            iconColor
          }
        }
        #Quote
        quoteAuthorImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        quoteContent
        quoteAuthor
        #Related Projects
        relatedProjects {
          title
          url
          ... on Craft_project_project_Entry {
            heroDescription
            heroBackgroundImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            projectCategories {
              title
              slug
            }
          }
        }
      }
    }
  }
}
`;

const previewQuery = `
query($slug: [String]) {
  craft {
    entry(section: "project", slug: $slug) {
      ... on Craft_project_project_Entry {
        #Meta
        title
        slug
        url
        metaTitle
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #Hero
        heroHeading
        heroDescription
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        projectCategories {
          title
          slug
        }
        #Environment Stats
        descriptor0
        descriptor1
        projectList {
          ...on Craft_projectList_projectList_BlockType {
            listLabel
            listContent
            color
          }
        }
        projectStats {
          ...on Craft_projectStats_projectStat_BlockType {
            statLabel
            statContent
            color
          }
        }
        #Benefits
        benefits {
          ...on Craft_benefits_benefit_BlockType {
            benefitName
            benefitDetails
            benefitImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            iconLocation
            iconColor
          }
        }
        #Quote
        quoteAuthorImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        quoteContent
        quoteAuthor
        #Related Projects
        relatedProjects {
          title
          url
          ... on Craft_project_project_Entry {
            heroDescription
            heroBackgroundImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            projectCategories {
              title
              slug
            }
          }
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    title,
    uid,
    url,
    slug,
    metaTitle,
    metaDescription,
    metaImage,
    // Hero
    heroHeading,
    heroDescription,
    heroBackgroundImage,
    projectCategories,
    // Enviromental Stats
    descriptor0,
    descriptor1,
    projectList,
    projectStats,
    // Benefits
    benefits,
    // Quote
    quoteAuthor,
    quoteContent,
    quoteAuthorImage,
    // Related projects
    relatedProjects,
  } = craft.entry;

  return {
    title,
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    hero: {
      heading: resolveBreaks(heroHeading),
      description: heroDescription,
      image: resolveImage(heroBackgroundImage),
      categories: projectCategories,
    },
    details: {
      heading: descriptor0,
      subhead: descriptor1,
      list: projectList.map(item => {
        return {
          label: item.listLabel,
          content: item.listContent,
          color: item.color,
        };
      }),
      stats: projectStats.map(stat => {
        return {
          label: stat.statLabel,
          statistic: stat.statContent,
          color: stat.color,
        };
      }),
    },
    benefits: benefits.map(benefit => {
      return {
        name: benefit.benefitName,
        details: benefit.benefitDetails,
        image: resolveImage(benefit.benefitImage),
        location: benefit.iconLocation,
        color: benefit.iconColor,
      }
    }),
    quote: {
      quote: quoteContent,
      author: quoteAuthor,
      image: resolveImage(quoteAuthorImage),
    },
    projects: relatedProjects.map(project => {
      return {
        title: project.title,
        description: project.heroDescription,
        image: resolveImage(project.heroBackgroundImage),
        url: project.url,
        categories: project.projectCategories.map( category => {
          return {
            title: category.title,
            slug: category.slug,
          };
        }),
      };
    }),
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
